import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { AnnulerAbonnementRequest } from '../models/request/abonnement/annulerAbonnementRequest';
import { ChangerEtatAbonnementRequest } from '../models/request/abonnement/changerEtatAbonnementRequest';
import { CommunicationForMiseEnServiceRequest } from '../models/request/abonnement/communicationForMiseEnServiceRequest';
import { DemandeBranchementContratExistantRequest } from '../models/request/abonnement/demandeBranchementContratExistantRequest';
import { DemandeBranchementRequest } from '../models/request/abonnement/demandeBranchementRequest';
import {
  GetCategoriesAbonnementFacturationByRueBody,
  GetCategoriesFacturationByTraiteRueBody
} from '../models/request/abonnement/getCategoriesAbonnementFacturationByRueBody';
import { MettreAJourModePaiementRequest } from '../models/request/abonnement/mettreAJourModePaiementRequest';
import { MettreAjourAdministratifRequest } from '../models/request/abonnement/mettreAjourAdministratifRequest';
import { MettreAjourCommentaireRequest } from '../models/request/abonnement/mettreAjourCommentaireRequest';
import { MettreAjourConcessionRequest } from '../models/request/abonnement/mettreAjourConcessionRequest ';
import { MettreAjourSegmentationRequest } from '../models/request/abonnement/mettreAjourSegmentationRequest';
import { mettreAjourServicesDiversRequest } from '../models/request/abonnement/mettreAjourServicesDiversRequest';
import { MiseEnServiceRequest } from '../models/request/abonnement/mettreEnServiceRequest';
import { MutationRequest } from '../models/request/abonnement/mutationRequest';
import { ResilierAbonnementRequest } from '../models/request/abonnement/resilierAbonnementRequest';
import { CommuneCompteurRequest } from '../models/request/accueil-csc/communeCompteurRequest';
import { CommuneRueNomRequest } from '../models/request/accueil-csc/communeRueNomRequest';
import { BranchementRequest } from '../models/request/compteur-installation/branchementRequest';
import { MettreAJourInformationsFacturationRequest } from '../models/request/facturation/mettreAJourInformationsFacturationRequest';
import { MettreAJourInformationsReleveRequest } from '../models/request/releve/mettreAJourInformationsReleveRequest';
import { GeneriqueResponse } from '../models/shared/generiqueResponse';
import { ParametersRequest } from './../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /Abonnement/Exist: Vérifie l'existence d'un abonnement en fonction de son numéro. Renvoie l'id de l'abonné
  /**
   * @param numeroAbonnement
   */
  public exist(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/Exist',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetContextAbonnement
  /**
   * @param numeroAbonnement
   */
  public getContextAbonnement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetContextAbonnement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/ExistByNumeroFacture: Vérifie l'existence d'un abonnement en fonction de son numéro. Renvoie l'id de l'abonné
  /**
   * @param numFacture
   */
  public existByNumeroFacture(numFacture: number): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/ExistByNumeroFacture',
      parameters: [{ name: 'numFacture', value: numFacture }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/RechercherByEmail: Renvoie une liste d'abonnement en fonction d'un email
  /**
   * @param email
   */
  public rechercherByEmail(email: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherByEmail',
      parameters: [{ name: 'email', value: email }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/RechercherByTelephone: Renvoie une liste d'abonnement en fonction d'un téléphone
  /**
   * @param phone
   */
  public rechercherByTelephone(phone: number): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherByTelephone',
      parameters: [{ name: 'numeroTelephone', value: phone }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region /Abonnement/GenererFicheAbonnement
  /**
   * @param numAbonnement
   */
  public genererFicheAbonnement(numAbonnement: number): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GenererFicheAbonnement',
      parameters: [{ name: 'numeroAbonnement', value: numAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region /Abonnement/RechercherByCommuneCompteur: Renvoie une liste d'abonnement en fonction d'un numéro de compteur physique
  /**
   * @param body
   */
  public rechercherByCommuneCompteur(body?: CommuneCompteurRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/RechercherByCommuneCompteur`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/RechercherByCommuneRueNom: Renvoie une liste d'abonnement en fonction de la commune et de la rue OU du nom (ou une partie du nom) OU de la commune, de la rue et du nom (ou une partie du nom)
  /**
   * @param body
   */
  public rechercherByCommuneRueNom(body?: CommuneRueNomRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/RechercherByCommuneRueNom`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetEtatAbonnementSuivantDisponible: Renseigne vers quel état peut basculer l'abonnement (hors process)
  /**
   * @param numeroAbonnement
   */
  public getEtatAbonnementSuivantDisponible(
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetEtatAbonnementSuivantDisponible',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsFinancier: Récupérer les informations financier d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsFinancier(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsFinancier',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsIdentite: Récupérer les informations d'identité d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsIdentite(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsIdentite',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsTechnique: Récupérer les informations techniques d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsTechnique(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsTechnique',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsConcession: Récupérer les informations de concession d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsConcession(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsConcession',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsSegmentation: Récupérer les informations de segmentation d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsSegmentation(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsSegmentation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /MutationParticulariteAbonnement/GetActiveByAbonnement: Récupérer les informations des particularités d'abonnement disponibles à la mutation d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getParticularitesAvailableForMutation(
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/MutationParticulariteAbonnement/GetActiveByAbonnement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsReleve: Récupérer les informations de segmentation d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsReleve(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsReleve',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsAdministrative: Récupérer les informations administratives d'un abonnement
  /**
   * @param numeroAbonnement
   **/
  public getInformationsAdministrative(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsAdministrative',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsServicesDivers: Récupérer les informations de services divers d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsServicesDivers(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsServicesDivers',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsCommentaire: Récupérer les informations administratives d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsCommentaire(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsCommentaire',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsRegroupement: Récupérer InformationsRegroupement
  /**
   * @param numeroAbonnement
   */
  public getInformationsRegroupement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsRegroupement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetCountRegroupeByRegroupant:
  /**
   * @param numeroAbonnement
   */
  public getCountRegroupeByRegroupant(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetCountRegroupeByRegroupant',
      parameters: [{ name: 'numeroAbonnementRegroupant', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetCountDivisionnaireByGeneral:
  /**
   * @param numeroAbonnement
   */
  public getCountDivisionnaireByGeneral(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetCountDivisionnaireByGeneral',
      parameters: [{ name: 'numeroAbonnementGeneral', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/DevenirGeneral:
  /**
   * @param indicateur
   * @param numeroAbonnement
   */
  public devenirGeneral(
    indicateur: boolean,
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/DevenirGeneral',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'indicateurTaxeAmenagementSruNonAppliquee', value: indicateur }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/DevenirDivisionnaire:
  /**
   * @param numeroAbonnement
   * @param numeroAbonnementGeneral
   */
  public devenirDivisionnaire(
    numeroAbonnement: string,
    numeroAbonnementGeneral: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/DevenirDivisionnaire',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'numeroAbonnementGeneral', value: numeroAbonnementGeneral }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/DevenirRegroupe:
  /**
   * @param numeroAbonnement
   * @param NumeroAbonnementRegroupant
   */
  public devenirRegroupe(
    numeroAbonnement: string,
    numeroAbonnementRegroupant: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/DevenirRegroupe',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'numeroAbonnementRegroupant', value: numeroAbonnementRegroupant }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/DevenirRegroupant:
  /**
   * @param numeroAbonnement
   */
  public devenirRegroupant(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/DevenirRegroupant',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/HasAnomalieTypeRegroupement: Renvoie une anomalie (true) ou non (false) si l'abonnement a plusieurs types de regroupement
  /**
   * @param numeroAbonnement
   */
  public hasAnomalieTypeRegroupement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/HasAnomalieTypeRegroupement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/IsAbonnementResiliable: Retourner si un abonnement est résiliable ou pas
  /**
   * @param numeroAbonnement
   */
  public isAbonnementResiliable(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/IsAbonnementResiliable',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/IsTechniquementResiliable: Retourner si un abonnement est techniquement résiliable ou pas
  /**
   * @param numeroAbonnement
   */
  public isTechniquementResiliable(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/IsTechniquementResiliable',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/SupprimerRegroupement: Ne plus être ni Général, ni Divisionnaire, ni Regroupé, ni Regroupant
  /**
   * @param numeroAbonnement
   */
  public supprimerRegroupement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/SupprimerRegroupement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Abonnement/RechercherByClient: Rechercher des abonnements en fonction du numéro client
  /**
   * @param numeroClient
   */
  public rechercherByClient(numeroClient: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherByClient',
      parameters: [{ name: 'numeroClient', value: numeroClient }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/RechercherByPointDesserte: Récupérer la liste des abonnements qui ont le même point de desserte
  /**
   * @param numeroPointDesserte
   */
  public rechercherByPointDesserte(numeroPointDesserte: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherByPointDesserte',
      parameters: [{ name: 'numeroPointDesserte', value: numeroPointDesserte }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/DemandeBranchementContratExistant
  /**
   * @param body
   */
  public demandeBranchementContratExistant(
    body: DemandeBranchementContratExistantRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/DemandeBranchementContratExistant`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetCategoriesAbonnementFacturationByTraite: Récupérer les catégories d'abonnement de facturation en fonction des traités juridique de l'abonnement
  /**
   * @param numeroAbonnement
   */
  public getCategoriesAbonnementFacturationByTraite(
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetCategoriesAbonnementFacturationByTraite',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  // #region
  /**
   * @param body
   */
  public annuler(body?: AnnulerAbonnementRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/Annuler`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region
  /**
   * @param body
   */
  public mettreAJourEtatSuiteMauvaiseManipulation(
    body?: ChangerEtatAbonnementRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourEtatSuiteMauvaiseManipulation`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetInformationsFacturation: Récupérer les informations de facturation d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsFacturation(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsFacturation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetCategoriesFacturationByTraiteContrat: Récupérer les catégories de facturation en fonction des traités juridique et la catégorie d'abonnement de l'abonnement
  /**
   * @param numeroAbonnement
   * @param codeCategorieAbonnement
   */
  public getCategoriesFacturationByTraiteContrat(
    numeroAbonnement: string,
    codeCategorieAbonnementFacturation: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetCategoriesFacturationByTraiteContrat',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'codeCategorieAbonnementFacturation', value: codeCategorieAbonnementFacturation }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  // --------------------------------------------------------

  //#region /Abonnement/GetInformationsForResiliation
  /**
   * @description Récupération des informations pour la résiliation de l'abonnement
   * @param numeroAbonnement
   */
  public getInformationsForResiliation(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsForResiliation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  // --------------------------------------------------------------------------------------------

  //#region /Abonnement/Resilier
  /**
   * @description Résilier un abonnement
   * @param body
   */
  public resilier(body: ResilierAbonnementRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/Resilier`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  // --------------------------------------------------------------------------------------------

  //#region /Abonnement/Suspendre
  /**
   * @description Suspendre un abonnement
   */
  public suspendre(
    body: string
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/Suspendre`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  // --------------------------------------------------------------------------------------------

  //#region /Abonnement/RechercherCategorieAbonnementByActivite: Rechercher la liste des catégories d'abonnement
  /**
   * @param codeActivite
   */
  public rechercherCategorieAbonnementByActivite(
    codeActivite: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherCategorieAbonnementByActivite',
      parameters: [{ name: 'codeActivite', value: codeActivite }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/RechercherSousCategorieAbonnement: Rechercher la liste des sous catégories d'abonnement
  /**
   * @param codeActivite
   * @param codeCategorieAbonnement
   */
  public rechercherSousCategorieAbonnement(
    codeActivite: string,
    codeCategorieAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/RechercherSousCategorieAbonnement',
      parameters: [
        { name: 'codeActivite', value: codeActivite },
        { name: 'codeCategorieAbonnement', value: codeCategorieAbonnement }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInformationsSegmentation: Modifier les informations de segmentation d'un abonnement
  /**
   * @param body
   */
  public mettreAJourInformationsSegmentation(
    body?: MettreAjourSegmentationRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsSegmentation`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInfosCommentaire: Modifier les informations de commentaire d'un abonnement
  /**
   * @param body
   */
  public mettreAJourInfosCommentaire(
    body?: MettreAjourCommentaireRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInfosCommentaire`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInformationsServicesDivers: Modifier les informations des services divers d'un abonnement
  /**
   * @param body
   */
  public mettreAJourInformationsServicesDivers(
    body?: mettreAjourServicesDiversRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsServicesDivers`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInformationsConcession: Modifier les informations de concession d'un abonnement
  /**
   * @param body
   */
  public mettreAJourInformationsConcession(
    body?: MettreAjourConcessionRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsConcession`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInfosAdministrative: Modifier les informations d'Administratif d'un abonnement
  /**
   * @param body
   */
  public mettreAJourInfosAdministrative(
    body?: MettreAjourAdministratifRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInfosAdministrative`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInformationsFacturation: Modifier les informations de facturation
  /**
   * @param body
   */
  public mettreAjourInformationsFacturation(
    body?: MettreAJourInformationsFacturationRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsFacturation`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetCategorieAbonnementFactByQuadrupleFacturation: Récupérer les informations de facturation d'un abonnement
  /**
   * @param codeTypeAbonnement
   * @param codeTypeAppareil
   * @param codeTypeReseau
   * @param codeTypeUsage
   */
  public getCategorieAbonnementFactByQuadrupleFacturation(
    codeTypeAbonnement: string,
    codeTypeAppareil: string,
    codeTypeReseau: string,
    codeTypeUsage: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetCategorieAbonnementFactByQuadrupleFacturation',
      parameters: [
        { name: 'codeTypeAbonnement', value: codeTypeAbonnement },
        { name: 'codeTypeAppareil', value: codeTypeAppareil },
        { name: 'codeTypeReseau', value: codeTypeReseau },
        { name: 'codeTypeUsage', value: codeTypeUsage }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetQuadrupleFacturationByCategorieAbonnementFact: Récupérer le quadruplé de facturation en fonction du code catégorie abonnement facturation
  /**
   * @param codeCategorieAbonnement
   */
  public getQuadrupleFacturationByCategorieAbonnementFact(
    codeCategorieAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetQuadrupleFacturationByCategorieAbonnementFact',
      parameters: [{ name: 'codeCategorieAbonnement', value: codeCategorieAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsPaiement: Récupérer les informations de paiement d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsPaiement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsPaiement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsBranchement: Récupérer les informations branchement d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsBranchement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsBranchement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/MettreAJourInformationsBranchement: Modifier les informations de branchement
  /**
   * @param body
   */
  public mettreAJourInformationsBranchement(
    body?: BranchementRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsBranchement`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/DemandeBranchement Process demande de branchement
  /**
   * @param body
   */
  public demandeBranchement(body?: DemandeBranchementRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/DemandeBranchement`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/IsRemboursable: Récupération info abonnement remboursable
  /**
   * @param numeroAbonnement
   */
  public isRemboursable(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/IsRemboursable',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/IsValidePourFaciliteDePaiement: l'abonné peut-il avoir des facilités de paiement ?
  /**
   * @param numeroAbonnement
   */
  public isValidePourFaciliteDePaiement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: `/Abonnement/IsValidePourFaciliteDePaiement/${numeroAbonnement}`,
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/IsValidePourEcheanciersDeMensualisation: l'abonné peut-il avoir des échéanciers de mensualisation ?
  /**
   * @param numeroAbonnement
   */
  public isValidePourEcheanciersDeMensualisation(
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/IsValidePourEcheanciersDeMensualisation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region Abonnement/MettreAJourInformationsReleve: Modifier les informations de releve
  /**
   * @param body
   */
  public mettreAJourInformationsReleve(
    body: MettreAJourInformationsReleveRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourInformationsReleve`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Abonnement/MettreAjourServicesDiversRequest: Modifier les informations des services divers d'un abonnement
  /**
   * @param body
   */
  public mettreAJourModePaiement(
    body?: MettreAJourModePaiementRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MettreAJourModePaiement`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Abonnement/SendPlaquettePubMail:Envoi d'un mail publicitaire du service à l'abonné (avec en PJ la plaquette publicitaire)
  /**
   * @param numeroAbonnement
   * @param codeTypeService
   */
  public SendPlaquettePubMail(
    numeroAbonnement: string,
    codeTypeService: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/SendPlaquettePubMail',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'codeTypeService', value: codeTypeService }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Abonnement/GetCategoriesAbonnementFacturationByRue
  /**
   * @param body
   */
  public getCategoriesAbonnementFacturationByRue(
    body?: GetCategoriesAbonnementFacturationByRueBody
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/GetCategoriesAbonnementFacturationByRue`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetCategoriesFacturationByTraiteRue
  /**
   * @param body
   */
  public getCategoriesFacturationByTraiteRue(
    body?: GetCategoriesFacturationByTraiteRueBody
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/GetCategoriesFacturationByTraiteRue`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetInformationsForMutation:récupère l'index et la date de résiliation (si fait dans les 15 jours précédent le jour actuel) ainsi que le numéro appareil
  /**
   * @param numeroAbonnement
   */
  public getInformationsForMutation(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsForMutation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsFactureEntrantForProcess: récupération des informations de facturation nécessaire au process de Mise en service / Mutation
  /**
   * @param dateEffet
   * @param numeroAbonnement
   */
  public getInformationsFactureEntrantForProcess(
    dateEffet: string,
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsFactureEntrantForProcess',
      parameters: [
        { name: 'dateEffet', value: dateEffet },
        { name: 'numeroAbonnement', value: numeroAbonnement }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region ​/Abonnement​/CalculFraisMiseEnService: Calcul des frais de mise en service
  /**
   * @param numeroAbonnement
   */
  public calculFraisMiseEnService(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/CalculFraisMiseEnService',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement​/Mutation : Mutation d'un abonnement
  /**
   * @param body
   */
  public mutation(body?: MutationRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/Mutation`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/IsMutable: Renvoie true si l'abonné passé en paramètre est mutable
  /**
   * @param numeroAbonnement
   */
  public isMutable(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/IsMutable',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/GetInformationsAbonnementForProcess: Récupérer les informations d'un abonnement pour tout process abonnement
  /**
   * @param numeroAbonnement
   */
  public getInformationsAbonnementForProcess(
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetInformationsAbonnementForProcess',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/MiseEnServiceLight: Mise en service d'un abonnement
  /**
   * @param body
   */
  public miseEnServiceLight(body?: MiseEnServiceRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/MiseEnServiceLight`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Abonnement/GetIndexConsentementComplexe: récupère l'index de Consentement Complexe
  /**
   * @param numeroAbonnement
   */
  public getIndexConsentementComplexe(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Abonnement/GetIndexConsentementComplexe',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Abonnement/CommunicationForMiseEnService: Communication pour la mise en service d'un abonnement
  /**
   * @param body
   */
  public communicationForMiseEnService(
    body?: CommunicationForMiseEnServiceRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Abonnement/CommunicationForMiseEnService`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
}
