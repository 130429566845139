import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  faCaretLeft,
  faInfoCircle,
  faTint,
  faUser,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable, lastValueFrom, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppResource } from 'src/app/app.resource';
import { shortcut } from 'src/app/core/models/header.model';
import { Card } from 'src/app/core/models/historique.model';
import * as fromAbonnement from 'src/app/core/state/abonnement';
import * as fromContext from 'src/app/core/state/context';
import * as fromRoot from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import * as fromHistorique from 'src/app/core/state/historique';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { openPDF, updateStorePDF } from 'src/app/shared/utils/pdfUtils';
import Swal from 'sweetalert2';

import { WebFonctions } from 'src/app/api/models/interface/WebFunction';
import { ClientService } from 'src/app/api/services/client.service';
import { haveAutorisation } from 'src/app/shared/utils/webFonctionsUtils';

//#region Import Material Module
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'primeng/tooltip';
import { FrameComponent } from 'src/app/shared/components/frame/frame.component';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';

import { Ressource } from 'src/app/api/models/class/Ressource';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { WaterpSwal } from '../../models/waterp-swal';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { SocialAuthService } from '@abacritt/angularx-social-login';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))])
    ])
  ],
  imports: [
    CommonModule,
    MenuComponent,
    FrameComponent,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    OverlayModule,
    RouterModule,
    TooltipModule,
    FontAwesomeModule,
    PdfViewerComponent
  ],
  standalone: true
})
export class LayoutComponent extends BaseComponent implements OnInit {
  @Input() title: string = 'Accueil CSC';
  isExpanded!: boolean;

  cards!: Observable<Card[]>;
  faTint = faTint;
  faUser = faUser;
  faUserCircle = faUserCircle;
  faInfoCircle = faInfoCircle;
  faCaretLeft = faCaretLeft;

  showShortcut: boolean = false;
  UserName!: string;
  UserMatricule!: string;
  isAdmin!: boolean;
  pdfShow: boolean = false;
  ouvrirPDF = openPDF;
  webFunctions!: WebFonctions;
  items!: MenuItem[];

  isOpen = false;
  numAbonnement!: number;
  shortcut!: shortcut;
  showCards = false;

  triggerPDS360!: {
    styleClass: string;
    visible: boolean;
    tooltip: string;
    url: string;
    icon: string;
  };

  toggleCards() {
    this.showCards = !this.showCards;
  }
  constructor(
    store: Store<fromRoot.State>,
    resources: AppResource,
    messageService: MessageService,
    private authService: SocialAuthService,
    private abonnementService: AbonnementService,
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private clientService: ClientService
  ) {
    super(store, resources, messageService);

    this.store.subscribe(async state => {
      let title = await lastValueFrom(this.store.select(fromHeader.selectTitle).pipe(take(1)));

      if (title) {
        this.title = title;
        this.showShortcut = title.includes('Abonnement n°');
      }
      let shortcut = await lastValueFrom(
        this.store.select(fromHeader.selectShortcut).pipe(take(1))
      );

      if (shortcut) this.shortcut = shortcut;

      this.triggerPDS360 = {
        styleClass: 'circle cursor-pointer',
        visible: shortcut?.IndicateurTeleReleve ?? false,
        tooltip: 'Application PDS 360',
        url: '',
        icon: 'icon waterpIcons-AccesPds360 iconWhite'
      };
      this.store.select(fromAbonnement.selectNumeroPointDesserte).subscribe(res => {
        this.triggerPDS360.url = this.clientService.getPds360Path() + res;
      });
      let isExpandedExists = await lastValueFrom(
        this.store.select(fromHeader.selectIsExpandedMenu).pipe(take(1))
      );
      if (isExpandedExists) {
        this.isExpanded = isExpandedExists;
      }

      let numAbonnementExist = await lastValueFrom(
        this.store.select(fromAbonnement.selectNumeroAbonnement).pipe(take(1))
      );
      if (numAbonnementExist) {
        this.numAbonnement = parseInt(numAbonnementExist);
      }
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.store.select(fromHeader.selectWebFunctions).subscribe(webFunctions => {
      this.webFunctions = webFunctions;
    });

    this.store
      .select(fromContext.selectContextRessource)
      .pipe(map(rsc => (rsc ? new Ressource(rsc) : null)))
      .subscribe(contextRessource => {
        if (contextRessource != null) {
          this.UserName = contextRessource.getNom();
          this.UserMatricule = contextRessource.getMatricule();
          this.isAdmin = contextRessource.getIsAdmin();
        }
      });

    this.primengConfig.ripple = true;
    this.items = [
      {
        label: this.resource.layout.profil,
        icon: 'pi pi-user'
      },
      {
        label: this.resource.layout.deconnexion,
        icon: 'pi pi-sign-out'
      }
    ];

    this.cards = this.store
      .select(fromHistorique.selectCards)
      .pipe(map(cards => (cards ? cards.slice().reverse() : [])));
  }
  // #endregion

  updateIsExpandedMenu() {
    this.isExpanded = !this.isExpanded;
    this.store.dispatch(fromHeader.updateIsExpandedMenu({ isExpandedMenu: this.isExpanded }));
  }

  logout() {
    BaseComponent.redirectToAuthentification(this.store, this.router);
    this.authService.signOut();
  }

  showPaiementModal() {
    this.store.dispatch(fromHeader.updatePaiement({ activePaiement: true }));
  }

  profil() {
    this.router.navigate(['/compte-utilisateur']);
    this.isOpen = false;
  }

  getIrisUrl(tierIdentifier: string | null): string {
    return this.clientService.getIrisPath() + '/lightning/r/Account/' + tierIdentifier + '/view';
  }

  navigateToSynthese(url: string, type?: string): void {
    if (type != undefined) this.setIndex(type);
    window.location.href = url;
  }
  setIndex(type: string) {
    if (type == 'IN') {
      this.store.dispatch(fromHistorique.updateHistoriqueIndex({ index: 5 }));
    } else if (type == 'CR') {
      this.store.dispatch(fromHistorique.updateHistoriqueIndex({ index: 7 }));
    }
  }

  haveAutorisations(code: string, webfonctions: WebFonctions) {
    return haveAutorisation(code, webfonctions);
  }
  genererFicheAbonnment() {
    this.abonnementService.genererFicheAbonnement(this.numAbonnement).subscribe({
      next: (res: any) => {
        if (res.Code == 100) {
          updateStorePDF(res.Result, 'Fiche abonné', this.store);
        } else {
          this.messageServiceUtils.warning(res.Description ?? this.resource.toast.errorDescription);
        }
      },
      error: () => {
        this.messageServiceUtils.error();
      }
    });
  }
}
