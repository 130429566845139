import { format } from "date-fns";

export const returnFalseIfNullOrUndefined = (value: any) => {
  if (value === undefined || value === null) {
    return false;
  } else {
    return value;
  }
};


export function ToStringStandard(date: Date): string {
  return format(date, 'dd/MM/yyyy');
}
