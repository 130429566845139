import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { lastValueFrom, map, Observable, switchMap, take, tap } from 'rxjs';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { CommuneByINSEEResponse } from 'src/app/api/models/response/commune/CommuneByINSEEResponse';
import { RueResponse } from 'src/app/api/models/response/rue/rueResponse';
import { CommuneService } from 'src/app/api/services/commune.service';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { RueService } from 'src/app/api/services/rue.service';
import { AppResource } from 'src/app/app.resource';
import { Localisation } from 'src/app/core/models/wizard.model';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Option } from 'src/app/shared/models/option';
import { Rue } from 'src/app/api/models/class/rue';

@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, InputTextModule, KeyFilterModule],
  standalone: true
})
export class LocalisationComponent extends BaseComponent implements OnInit {
  //#region Input/Output
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() codeINSEE!: string;
  @Input() idTiersAbo!: string | null;
  @Input() idTiersDest!: string | null;
  //#endregion

  //#region Variables
  communeByINSEE!: CommuneByINSEEResponse;
  localisation!: Localisation;

  cities!: DropDownList[];
  streets!: Option<number, string>[];

  clearStreet: boolean = true;

  submitted: boolean = false;
  //#endregion

  //#region FormGroup
  localisationForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get commune() {
    return this.localisationForm.get('commune');
  }

  get rue() {
    return this.localisationForm.get('rue');
  }

  get numeroRue() {
    return this.localisationForm.get('numeroRue');
  }

  get cpl() {
    return this.localisationForm.get('cpl');
  }

  get etage() {
    return this.localisationForm.get('etage');
  }

  get complementAdresse() {
    return this.localisationForm.get('complementAdresse');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private router: Router,
    private parametrageGeneriqueService: ParametrageGeneriqueService,
    private rueService: RueService,
    private communeService: CommuneService
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    //Initialisation du refDataManager
    await this.initDataManager();

    this.localisation = await lastValueFrom(
      this.store.select(fromWizard.selectLocalisationData).pipe(take(1))
    );

    this.initData();
    this.initDataForm(this.localisation);

    this.localisationForm.valueChanges.subscribe(res => {
      this.automaticSave();
      if (this.submitted) this.submitted = false;
    });
  }

  //#region Init Data Manager
  async initDataManager() {
    this.cities = await getDataFromLocaleStorage(
      this.resource.refDataManager.commune,
      this.parametrageGeneriqueService
    );
  }
  //#endregion

  //#region Init Form & Data
  initForm() {
    this.localisationForm = new FormGroup(
      {
        commune: new FormControl<DropDownList | null>(null, Validators.required),
        rue: new FormControl<DropDownList | null>(
          { value: null, disabled: true },
          Validators.required
        ),
        numeroRue: new FormControl<string | null>(null, Validators.maxLength(4)),
        cpl: new FormControl<string | null>(null, Validators.maxLength(1)),
        etage: new FormControl<string | null>(null, Validators.maxLength(3)),
        complementAdresse: new FormControl<string | null>(null, Validators.maxLength(35))
      },
      {
        updateOn: 'change'
      }
    );

    this.commune?.valueChanges
      .pipe(
        tap(() => this.rue?.reset(undefined, { emitEvent: false })),
        switchMap((commune: DropDownList) => this.getStreetList(commune))
      )
      .subscribe();
  }

  public getStreetList(commune: DropDownList): Observable<GeneriqueResponse> {
    return this.rueService.getRuesByCodeCommune(commune.Key).pipe(
      map((response: GeneriqueResponse) => {
        if (response.Code != 100) {
          this.messageServiceUtils.warning(
            response.Description ?? this.resource.toast.errorDescription
          );
        }
        if ((response.Result?.length ?? 0) === 0) {

          // Dans le cas où on reçoit une commune, mais que celle-ci ne contient aucune rue (problème de paramétrage)
          // On force la commune à null pour obliger l'utilisateur à saisir manuellement une autre commune
          this.commune?.setValue(null, { emitEvent: false });

          this.rue?.disable({ emitEvent: false });
          response.Result = new Array<Option<number, string>>();
        } else {
          this.rue?.enable({ emitEvent: false });
          response.Result = response.Result.map((rue: RueResponse) => new Rue(rue).toOption()).sort(
            (a: Option<number, string>, b: Option<number, string>) => a.key - b.key
          );
        }
        this.streets = response.Result;
        return response;
      })
    );
  }

  private initData() {
    this.communeService.getCommuneByInsee(this.codeINSEE).subscribe({
      next: res => {
        if (res.Code !== 100) {
          return;
        }

        this.communeByINSEE = ApiResponseBodyUtils.ExtractResponseBody<CommuneByINSEEResponse>(res);
        if (!this.communeByINSEE) {
          this.messageServiceUtils.warning(this.resource.toast.errorDescriptionCommune);
          return;
        }

        let selectedCommune: DropDownList | undefined = this.cities.find(
          (res: DropDownList) => res.Value === this.communeByINSEE.Libelle
        );
        if (!selectedCommune) {
          return;
        }

        this.commune?.setValue(selectedCommune, { emitEvent: false });
        this.getStreetList(this.commune?.value).subscribe();
      },
      error: () => {
        this.messageServiceUtils.error();
      }
    });
  }

  private initDataForm(localisation: Localisation) {
    this.initCommune(localisation);
    this.cpl?.setValue(localisation.cpl, { emitEvent: false });
    this.etage?.setValue(localisation.etage, { emitEvent: false });
    this.numeroRue?.setValue(localisation.numeroRue, { emitEvent: false });
    this.complementAdresse?.setValue(localisation.complementAdresse, { emitEvent: false });
  }

  private initCommune(localisation: Localisation): void {
    let selectedCommune: DropDownList | undefined = localisation.commune
      ? this.cities.find((res: DropDownList) => res.Key === localisation.commune)
      : undefined;

    if (!selectedCommune) {
      return;
    }

    this.commune?.setValue(selectedCommune, { emitEvent: false });
    this.getStreetList(this.commune?.value).subscribe(() => {
      if (localisation.rue) {
        this.rue?.setValue(localisation.rue, { emitEvent: false });
      }
    });
  }

  submitForm() {
    this.submitted = !this.localisationForm.valid;
    if (this.localisationForm.valid) {
      this.validForm.emit(true);
    }else{
      this.localisationForm.markAllAsTouched();
    }
  }

  cancelForm() {
    this.initData();
    this.initDataForm(this.localisation);
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateLocalisation({
        payload: {
          commune: this.commune?.value?.Key,
          ville: this.communeByINSEE?.Libelle,
          codePostal: this.communeByINSEE?.FormatedCP,
          rue: this.rue?.value,
          numeroRue: this.numeroRue?.value,
          cpl: this.cpl?.value,
          etage: this.etage?.value,
          complementAdresse: this.complementAdresse?.value,
          idTiersAbo: this.idTiersAbo,
          idTiersDest: this.idTiersDest
        }
      })
    );
  }
  //#endregion
}
