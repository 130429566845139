import { LayoutComponent } from './../components/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AbonnementSyntheseComponent } from 'src/app/views/abonnement/synthese/abonnement-synthese/abonnement-synthese.component';
import { DemandeBranchementComponent } from 'src/app/views/demande-branchement/demande-branchement.component';
import { AuthGuard } from '../guards/auth.guard';
import { RedirectDemandeBranchementGuard } from '../guards/redirect-demande-branchement.guard';
import { RedirectMutationGuard } from '../guards/redirect-mutation.guard';
import { NouvelAbonnementComponent } from 'src/app/views/nouvel-abonnement/nouvel-abonnement.component';
import { RedirectNouvelAbonnementGuard } from '../guards/redirect-nouvel-abonnement.guard';
import { ConsulterInterventionComponent } from 'src/app/views/abonnement/synthese/interventions/consulter-intervention/consulter-intervention.component';
import { RedirectInterventionSyntheseGuard } from '../guards/redirect-intervention-synthese.guard';
import { GetInterventionResolver } from '../resolvers/interventions/get-intervention.resolver';
import { GetLightSearchListsResolver } from '../resolvers/ressources/get-light-search-lists.resolver';
import { ReinitialisationPasswordGuard } from '../guards/reinitialiser-mot-de-pass-token-valid.guard';
import { OrganisationGuard } from '../guards/organisation.guard';
import { GetProfilsResolver } from '../resolvers/profils/get-profils.resolver';
import { GetAbonnementResolver } from '../resolvers/abonnements/get-abonnements.resolver';
import { GetAllCommunesResolver } from '../resolvers/global/get-all-communes.resolver';
import { GetServicesResolver } from '../resolvers/abonnements/get-services.resolver';
import { GetRessourceResolver } from '../resolvers/ressources/get-ressources.resolver';
import { GetUnitiesResolver } from '../resolvers/ressources/get-unities.resolver';
import { GetAutorisationsResolver } from '../resolvers/profils/get-autorisations.resolver';
import { GetZonesAvecCentresResolver } from '../resolvers/ressources/get-zones-avec-centres.resolver';
import { AbonnementExistGuard } from '../guards/abonnement-exists.guard';

const routeClientSynthese = [
  {
    path: '',
    outlet: 'client-administratif',
    loadComponent: () =>
      import(
        'src/app/views/client/synthese/client-administratif/client-administratif.component'
      ).then(m => m.ClientAdministratifComponent)
  },
  {
    path: '',
    outlet: 'client-adresse',
    loadComponent: () =>
      import('src/app/views/client/synthese/client-adresse/client-adresse.component').then(
        m => m.ClientAdresseComponent
      )
  },
  {
    path: '',
    outlet: 'client-communication',
    loadComponent: () =>
      import(
        'src/app/views/client/synthese/client-communication/client-communication.component'
      ).then(m => m.ClientCommunicationComponent)
  },
  {
    path: '',
    outlet: 'client-facturation',
    loadComponent: () =>
      import('src/app/views/client/synthese/client-facturation/client-facturation.component').then(
        m => m.ClientFacturationComponent
      )
  },
  {
    path: '',
    outlet: 'client-abonnement',
    loadComponent: () =>
      import('src/app/views/client/synthese/client-abonnements/client-abonnements.component').then(
        m => m.ClientAbonnementsComponent
      )
  },
  {
    path: '',
    outlet: 'evenement-list',
    data: { page: 'synthese-client' },
    loadComponent: () =>
      import('src/app/shared/components/evenements-list/evenements-list.component').then(
        m => m.EvenementsList
      )
  }
];

const routeAbonnementSynthese = [
  {
    path: '',
    outlet: 'informations-identite',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/informations-identite/informations-identite.component'
      ).then(m => m.InformationsIdentiteComponent)
  },
  {
    path: '',
    outlet: 'informations-financiers',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/informations-financiers/informations-financiers.component'
      ).then(m => m.InformationsFinanciersComponent)
  },
  {
    path: '',
    outlet: 'informations-techniques',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/informations-techniques/informations-techniques.component'
      ).then(m => m.InformationsTechniquesComponent)
  },
  {
    path: '',
    outlet: 'resilier-abonnement',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/resilier-abonnement/resilier-abonnement.component'
      ).then(m => m.ResilierAbonnementComponent)
  },
  {
    path: '',
    outlet: 'mise-en-service-abonnement',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/mise-en-service-abonnement/mise-en-service-abonnement.component'
      ).then(m => m.MiseEnServiceAbonnementComponent)
  },
  {
    path: '',
    outlet: 'services',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/services/list-services/list-services.component'
      ).then(m => m.ListServicesComponent)
  },
  {
    path: '',
    outlet: 'devis-contrat',
    loadComponent: () =>
      import('src/app/views/abonnement/synthese/devis-contrat/devis-contrat.component').then(
        m => m.DevisContratComponent
      )
  },
  {
    path: '',
    outlet: 'evenement-list',
    data: { page: 'synthese-abonnement' },
    loadComponent: () =>
      import('src/app/shared/components/evenements-list/evenements-list.component').then(
        m => m.EvenementsList
      )
  },
  {
    path: '',
    outlet: 'generalites',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/generalites/generalites/generalites.component'
      ).then(m => m.GeneralitesComponent),
    children: [
      {
        path: '',
        outlet: 'concession',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/concession/concession.component'
          ).then(m => m.ConcessionComponent)
      },
      {
        path: '',
        outlet: 'segmentation',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/segmentation/segmentation.component'
          ).then(m => m.SegmentationComponent)
      },
      {
        path: '',
        outlet: 'administratif',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/administratif/administratif.component'
          ).then(m => m.AdministratifComponent)
      },
      {
        path: '',
        outlet: 'proprietaire-gerant',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/proprietaire-gerant/proprietaire-gerant.component'
          ).then(m => m.ProprietaireGerantComponent)
      },
      {
        path: '',
        outlet: 'services-divers',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/services-divers/services-divers.component'
          ).then(m => m.ServicesDiversComponent)
      },
      {
        path: '',
        outlet: 'commentaire',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/commentaire/commentaire.component'
          ).then(m => m.CommentaireComponent)
      },
      {
        path: '',
        outlet: 'generaux-regroupants',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/generalites/generaux-regroupants/generaux-regroupants.component'
          ).then(m => m.GenerauxRegroupantsComponent)
      }
    ]
  },
  {
    path: '',
    outlet: 'facturation-releve',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/facturation-releve/facturation-releve/facturation-releve.component'
      ).then(m => m.FacturationReleveComponent),
    children: [
      {
        path: '',
        outlet: 'facturation',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/facturation-releve/facturation/facturation.component'
          ).then(m => m.FacturationComponent)
      },
      {
        path: '',
        outlet: 'releve',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/facturation-releve/releve/releve.component'
          ).then(m => m.ReleveComponent)
      }
    ]
  },
  {
    path: '',
    outlet: 'compteur-installation',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compteur-installation/compteur-installation/compteur-installation.component'
      ).then(m => m.CompteurInstallationComponent),
    children: [
      {
        path: '',
        outlet: 'branchement',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compteur-installation/branchement/branchement.component'
          ).then(m => m.BranchementComponent)
      },
      {
        path: '',
        outlet: 'compteur',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compteur-installation/compteur/compteur.component'
          ).then(m => m.CompteurComponent)
      },
      {
        path: '',
        outlet: 'installations',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compteur-installation/installations/installations.component'
          ).then(m => m.InstallationsComponent)
      },
      {
        path: '',
        outlet: 'radio-releve',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compteur-installation/radio-releve/radio-releve.component'
          ).then(m => m.RadioReleveComponent)
      }
    ]
  },
  {
    path: '',
    outlet: 'consommations',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/consommations/list-consommations/list-consommations.component'
      ).then(m => m.ConsommationsComponent)
  },
  {
    path: '',
    outlet: 'ajout-consommations',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/consommations/ajout-consommations/ajout-consommations.component'
      ).then(m => m.AjoutConsommationsComponent)
  },
  {
    path: '',
    outlet: 'list-interventions',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/list-interventions/list-interventions.component'
      ).then(m => m.ListInterventionsComponent)
  },
  {
    path: '',
    outlet: 'ajout-interventions',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/ajout-interventions/ajout-interventions.component'
      ).then(m => m.AjoutInterventionsComponent)
  },
  {
    path: '',
    outlet: 'consulter-intervention',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/consulter-intervention/consulter-intervention.component'
      ).then(m => m.ConsulterInterventionComponent)
  },
  {
    path: '',
    outlet: 'modifier-intervention',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/modifier-intervention/modifier-intervention.component'
      ).then(m => m.ModifierInterventionComponent)
  },
  {
    path: '',
    outlet: 'compte',
    loadComponent: () =>
      import('src/app/views/abonnement/synthese/compte/compte/compte.component').then(
        m => m.CompteComponent
      ),
    children: [
      {
        path: '',
        outlet: 'dossier-compte',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compte/dossier-compte/dossier-compte.component'
          ).then(m => m.DossierCompteComponent)
      },
      {
        path: '',
        outlet: 'mensualisation',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compte/mensualisation/mensualisation.component'
          ).then(m => m.MensualisationComponent)
      },
      {
        path: '',
        outlet: 'fpa',
        loadComponent: () =>
          import('src/app/views/abonnement/synthese/compte/fpa/fpa.component').then(
            m => m.FpaComponent
          )
      },
      {
        path: '',
        outlet: 'remboursement',
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/compte/remboursement/remboursement.component'
          ).then(m => m.RemboursementComponent)
      },
      {
        path: '',
        outlet: 'fsl',
        loadComponent: () =>
          import('src/app/views/abonnement/synthese/compte/fsl/fsl.component').then(
            m => m.FslComponent
          )
      },
      {
        path: '',
        outlet: 'frais',
        loadComponent: () =>
          import('src/app/views/abonnement/synthese/compte/frais/frais.component').then(
            m => m.FraisComponent
          )
      }
    ]
  },
  {
    path: '',
    outlet: 'plan-mensualisation',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/plan-mensualisation/plan-mensualisation.component'
      ).then(m => m.PlanMensualisationComponent)
  },
  {
    path: '',
    outlet: 'creation-remboursement',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/creation-remboursement/creation-remboursement.component'
      ).then(m => m.CreationRemboursementComponent)
  },
  {
    path: '',
    outlet: 'echeancier-paiement',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/echeancier-paiement/echeancier-paiement.component'
      ).then(m => m.EcheancierPaiementComponent)
  },
  {
    path: '',
    outlet: 'facture-manuelle',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/facture-manuelle/facture-manuelle/facture-manuelle.component'
      ).then(m => m.FactureManuelleComponent)
  },
  {
    path: '',
    outlet: 'autre-facture-manuelle',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/autre-facture-manuelle/autre-facture-manuelle/autre-facture-manuelle.component'
      ).then(m => m.AutreFactureManuelleComponent)
  },
  {
    path: '',
    outlet: 'creer-avoir-partiel',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/creer-avoir-partiel/creer-avoir-partiel.component'
      ).then(m => m.CreerAvoirPartielComponent)
  },
  {
    path: '',
    outlet: 'creer-avoir-total',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/compte/creer-avoir-total/creer-avoir-total.component'
      ).then(m => m.CreerAvoirTotalComponent)
  },
  {
    path: '',
    outlet: 'communications',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/communications/communications/communications.component'
      ).then(m => m.CommunicationsComponent)
  },
  {
    path: '',
    outlet: 'nouveau-courrier',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/communications/nouveau-courrier/nouveau-courrier.component'
      ).then(m => m.NouveauCourrierComponent)
  },
  {
    path: '',
    outlet: 'nouveau-courrier-a-transferer',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/communications/nouveau-courrier-a-transferer/nouveau-courrier-a-transferer.component'
      ).then(m => m.NouveauCourrierATransfererComponent)
  }
];

const routeAccueil = [
  {
    path: '',
    outlet: 'ajout-interventions',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/ajout-interventions/ajout-interventions.component'
      ).then(m => m.AjoutInterventionsComponent)
  },
  {
    path: '',
    outlet: 'devis-commune',
    loadComponent: () =>
      import('src/app/views/accueil/devis-commune/devis-commune.component').then(
        m => m.DevisCommuneComponent
      )
  },
  {
    path: '',
    outlet: 'devis-contrat',
    loadComponent: () =>
      import('src/app/views/abonnement/synthese/devis-contrat/devis-contrat.component').then(
        m => m.DevisContratComponent
      )
  }
];

const routeIntervention = [
  {
    path: '',
    outlet: 'consulter-intervention',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/consulter-intervention/consulter-intervention.component'
      ).then(m => m.ConsulterInterventionComponent)
  },
  {
    path: '',
    outlet: 'modifier-intervention',
    loadComponent: () =>
      import(
        'src/app/views/abonnement/synthese/interventions/modifier-intervention/modifier-intervention.component'
      ).then(m => m.ModifierInterventionComponent)
  }
];

const routes: Routes = [
  {
    path: 'authentification',
    title: 'Connexion - Wat.erp Web',
    loadComponent: () =>
      import('src/app/shared/components/connexion/connexion.component').then(
        m => m.ConnexionComponent
      )
  },
  {
    path: 'mot-de-passe-oublie',
    title: 'Mot de passe oublié - Wat.erp Web',
    loadComponent: () =>
      import('src/app/views/mot-de-passe-oublie/mot-de-passe-oublie.component').then(
        m => m.MotDePasseOublieComponent
      )
  },
  {
    path: 'reinitialiser-mot-de-passe/:tokenReinitialisationMdp',
    title: 'Réinitialiser le mot de passe - Wat.erp Web',
    canActivate: [ReinitialisationPasswordGuard],
    loadComponent: () =>
      import('src/app/views/reinitialiser-mot-de-passe/reinitialiser-mot-de-passe.component').then(
        m => m.ReinitialiserMotDePasseComponent
      )
  },
  {
    path: 'ihm-validation-avoir/:token/:codeAction/:matricule/:numFacture',
    title: 'Validation Avoir',
    loadComponent: () =>
      import(
        'src/app/views/validation-avoir-remboursement/validation-avoir-remboursement.component'
      ).then(m => m.ValidationAvoirRemboursementComponent)
  },
  {
    path: 'ihm-validation-remboursement/:token/:codeAction/:matricule/:numAbonnement/:dateCreation',
    title: 'Validation Remboursement',
    loadComponent: () =>
      import(
        'src/app/views/validation-avoir-remboursement/validation-avoir-remboursement.component'
      ).then(m => m.ValidationAvoirRemboursementComponent)
  },
  {
    path: '',
    redirectTo: 'accueil-csc',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'accueil-csc',
        title: 'Accueil CSC - Wat.erp Web',
        canActivate: [AuthGuard],
        resolve: { communes: GetAllCommunesResolver },
        loadComponent: () =>
          import('src/app/views/accueil/accueil-csc/accueil-csc.component').then(
            m => m.AccueilCscComponent
          ),
        children: routeAccueil
      },
      {
        path: 'abonnement/synthese/:numAbonnement',
        resolve: { abonnement: GetAbonnementResolver, services: GetServicesResolver },
        pathMatch: 'prefix',
        canActivate: [AuthGuard, AbonnementExistGuard],
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/abonnement-synthese/abonnement-synthese.component'
          ).then(m => m.AbonnementSyntheseComponent),
        children: [
          {
            path: ':actionByProcess',
            children: [
              { path: ':idProcess', children: routeAbonnementSynthese },
              { path: ':idTiersAbo/:idTiersDest', children: routeAbonnementSynthese },
              ...routeAbonnementSynthese
            ]
          },
          ...routeAbonnementSynthese
        ]
      },
      {
        path: 'Abonnement/Synthese',
        canActivate: [RedirectMutationGuard],
        component: AbonnementSyntheseComponent
      },
      {
        path: 'client/synthese/:numClient',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/client/synthese/client-synthese/client-synthese.component').then(
            m => m.ClientSyntheseComponent
          ),
        children: [
          {
            path: '',
            children: routeClientSynthese
          },
          {
            path: ':numIris',
            children: routeClientSynthese
          }
        ]
      },
      {
        path: 'demande-branchement/:codeInsee/:idTiersAbo/:idTiersDest',
        title: 'Demande branchement - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/demande-branchement/demande-branchement.component').then(
            m => m.DemandeBranchementComponent
          )
      },
      {
        path: 'Branchement/Branchement',
        canActivate: [RedirectDemandeBranchementGuard],
        component: DemandeBranchementComponent
      },
      {
        path: 'nouvel-abonnement/:codeInsee/:idTiersAbo/:idTiersDest',
        title: 'Nouvel abonnement - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/nouvel-abonnement/nouvel-abonnement.component').then(
            m => m.NouvelAbonnementComponent
          )
      },
      {
        path: 'Abonnement/CreerAbonnement',
        canActivate: [RedirectNouvelAbonnementGuard],
        component: NouvelAbonnementComponent
      },
      {
        path: 'Home/About',
        title: 'A propos - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/a-propos/a-propos.component').then(m => m.AProposComponent)
      },
      {
        path: 'erreur',
        title: 'Erreur - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/erreur/erreur.component').then(m => m.ErreurComponent)
      },
      {
        path: 'demande-branchement-non-geree',
        title: 'Branchement non géré - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            'src/app/views/demande-branchement-non-geree/demande-branchement-non-geree.component'
          ).then(m => m.DemandeBranchementNonGereeComponent)
      },
      {
        path: 'recherche-intervention',
        title: 'Recherche intervention - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            'src/app/views/intervention/recherche-intervention/recherche-intervention.component'
          ).then(m => m.RechercheInterventionComponent),
        children: routeIntervention
      },
      {
        path: 'intervention/recherche-localisation',
        title: 'Recherche intervention par localisation - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            'src/app/views/intervention/recherche-par-localisation/recherche-par-localisation.component'
          ).then(m => m.RechercheParLocalisationComponent),
        children: routeIntervention
      },
      {
        path: 'intervention/synthese/:numIntervention',
        canActivate: [AuthGuard],
        resolve: { intervention: GetInterventionResolver },
        loadComponent: () =>
          import(
            'src/app/views/abonnement/synthese/interventions/interventions-hub/interventions-hub.component'
          ).then(m => m.InterventionsHubComponent),
        children: routeIntervention
      },
      {
        path: 'Intervention/Synthese',
        canActivate: [RedirectInterventionSyntheseGuard],
        component: ConsulterInterventionComponent
      },
      {
        path: 'recherche-client',
        title: 'Recherche client - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/client/recherche-client/recherche-client.component').then(
            m => m.RechercheClientComponent
          )
      },
      {
        path: 'recherche-abonnement',
        title: 'Recherche abonnement - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            'src/app/views/abonnement/recherche-abonnement/recherche-abonnement.component'
          ).then(m => m.RechercheAbonnementComponent)
      },
      {
        path: 'compte-utilisateur',
        title: 'Compte utilisateur - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import('src/app/views/client-profil/client-profil.component').then(
            m => m.ClientProfilComponent
          )
      },
      {
        path: 'organisation/autorisation-ressource',
        title: 'Gestion des autorisations - Wat.erp Web',
        canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            'src/app/views/autorisation-ressource/autorisation-ressource-main/autorisation-ressource.component'
          ).then(m => m.AutorisationRessourceComponent)
      },
      {
        path: 'organisation',
        canActivate: [AuthGuard, OrganisationGuard],
        resolve: {
          allAutorisations: GetAutorisationsResolver
        },
        children: [
          {
            path: 'gestion-ressources',
            title: 'Gestion des ressources - Wat.erp Web',
            loadComponent: () =>
              import(
                'src/app/views/organisation/ressource-management/ressource-management.component'
              ).then(m => m.ResourceManagementComponent),
            resolve: { lightSearchLists: GetLightSearchListsResolver },
            children: [
              {
                path: 'search',
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/ressource-management/components/ressource-search/ressource-search.component'
                  ).then(m => m.RessourceSearchComponent)
              },
              {
                path: 'form',
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/ressource-management/components/ressource-form/ressource-form.component'
                  ).then(m => m.RessourceFormComponent),
                resolve: {
                  unities: GetUnitiesResolver,
                  zonesAvecCentres: GetZonesAvecCentresResolver
                }
              },

              {
                path: 'mass-creation',
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/ressource-management/components/ressource-mass-creation/ressource-mass-creation.component'
                  ).then(m => m.RessourceMassCreationComponent)
              }
            ]
          },
          {
            path: 'gestion-ressources',
            title: 'Gestion des ressources - Wat.erp Web',
            loadComponent: () =>
              import(
                'src/app/views/organisation/ressource-management/components/ressource-update/ressource-update.component'
              ).then(m => m.RessourceUpdateComponent),
            resolve: {
              lightSearchLists: GetLightSearchListsResolver,
              zonesAvecCentres: GetZonesAvecCentresResolver
            },
            children: [
              {
                path: 'dossier/:matricule',
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/ressource-management/components/ressource-form/ressource-form.component'
                  ).then(m => m.RessourceFormComponent),
                resolve: {
                  completeRessource: GetRessourceResolver,
                  unities: GetUnitiesResolver,
                  zonesAvecCentres: GetZonesAvecCentresResolver
                }
              }
            ]
          },
          {
            path: 'gestion-profils',
            title: 'Gestion des Profils - Wat.erp Web',
            loadComponent: () =>
              import(
                'src/app/views/organisation/profil-management/profil-management.component'
              ).then(m => m.ProfilManagementComponent),
            children: [
              {
                path: 'list',
                resolve: { profils: GetProfilsResolver },
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/profil-management/components/profil-list/profil-list.component'
                  ).then(m => m.ProfilListComponent)
              },
              {
                path: 'form',
                resolve: {
                  profil: GetProfilsResolver,
                  autorisations: GetAutorisationsResolver
                },
                loadComponent: () =>
                  import(
                    'src/app/views/organisation/profil-management/components/profil-form/profil-form.component'
                  ).then(m => m.ProfilFormComponent)
              }
            ]
          },
          {
            path: 'profil/:codeProfil',
            resolve: {
              profil: GetProfilsResolver,
              autorisations: GetAutorisationsResolver
            },
            loadComponent: () =>
              import(
                'src/app/views/organisation/profil-management/components/profil-form/profil-form.component'
              ).then(m => m.ProfilFormComponent)
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'accueil-csc',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
