import { ApiResponseBodyUtils } from './apiResponseBodyUtils';
import { Data } from '../models/reference-data.model';
import { lastValueFrom } from 'rxjs';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { KeyValueResponse } from 'src/app/api/models/response/key-value/keyValueResponse';
import { RefDataManagerService } from 'src/app/api/services/refDataManager.service';

//#region LocalStorage
export function getItemLocalStorage(key: string) {
  return localStorage.getItem(key);
}

export function getItemLocalStorageJson(key: string) {
  return JSON.parse(localStorage.getItem(key)!);
}

export function setItemLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function setItemLocalStorageJson(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeItemLocalStorage(key: string) {
  localStorage.removeItem(key);
}
//#endregion

export async function getDataFromLocaleStorage(
  key: string,
  parametrageGeneriqueService: ParametrageGeneriqueService,
  rewrite: boolean = false
): Promise<Data[]> {
  let data: Data[] = [];

  data = getItemLocalStorageJson(key);

  if ((!data && !rewrite) || rewrite) {
    const KeyValueList = ApiResponseBodyUtils.ExtractArrayResponseBody<KeyValueResponse>(
      await lastValueFrom(parametrageGeneriqueService.getAllList(key))
    );

    data = KeyValueList
      ? KeyValueList.filter(obj => obj['IsActive'] != '*').map(obj => {
          return {
            Key: obj.Code ?? obj['Identifiant'],
            Value: obj.Libelle ?? obj['LibelleTypeHabitation']
          };
        })
      : [];

    setItemLocalStorageJson(key, data);
  }

  return data;
}

//Les chargements se font au fil de l'eau, l'appel à cette fonction
//est déprécié parce que ça DDOS le serveur
export async function loadRefDataManager(
  parametrageGeneriqueService: ParametrageGeneriqueService,
  refDataManagerService: RefDataManagerService
) {
  const refData = refDataManagerService.getData();

  refData.forEach(res => {
    getDataFromLocaleStorage(res, parametrageGeneriqueService, true);
  });
}
